import {BREAKPOINT} from 'aac-components/utils/styles';

const BlockWithIntroCopy = (props) => {
    const {
        block_title = '',
        block_content = '',
        block_intro_copy = '',
    } = props;


    return (
        <div className="block">
            <div className="block__grid">
                <div>
                    <h2
                        dangerouslySetInnerHTML={{__html: block_title}}
                        className="block__title"
                    />{' '}
                    <div
                        className="block__intro"
                        dangerouslySetInnerHTML={{__html: block_intro_copy}}
                    />
                </div>
                <div>
                    <div
                        className="block__content"
                        dangerouslySetInnerHTML={{__html: block_content}}
                    />
                </div>
            </div>
            <style jsx>
                {`
                    .block {
                        padding-bottom: 40px;
                        border-bottom: 2px solid var(--gray-200);
                        margin-bottom: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .block {
                            padding-bottom: 64px;
                            margin-bottom: 64px;
                        }
                    }
                    .block__title {
                        margin-bottom: 40px;
                    }
                    .block__intro {
                        font-size: 16px;
                        line-height: 24px;
                    }
                    .block__grid {
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 40px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .block__grid {
                            grid-template-columns: 1fr 2fr;
                            grid-gap: 80px;
                            align-items: flex-start;
                        }
                    }
                `}
            </style>
        </div>
    );
};
export default BlockWithIntroCopy;
